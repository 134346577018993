import { Tag } from "model/tag";
import { Badge, Box } from "@chakra-ui/react";
import Link from "next/link";
import { FC } from "react";
interface Props {
  tag: Tag[];
}
const Tags: FC<Props> = ({ tag }) => {
  return (
    <Box>
      {tag?.map((item, i) => {
        return <Badge cursor={"pointer"} my={1} mx={1} key={i} bg={"gray.600"} color={"white"} p={1} px={2} as={Link} href={`/post?tag=${item.attributes.tag}`}>#{item.attributes.tag}</Badge>;
      })}
    </Box>
  );
};

export default Tags;
