import { Box, Button, Heading, Stack, Text, Image, Badge } from "@chakra-ui/react";
import Container from "../Container";
import useDarkmode from "@/hooks/userDarkmode";
import { cdn2 } from "@/utils/image";
import { useRouter } from "next/router";
import { useAppState } from "store/hookstate/app.state";
import { FC } from "react";
import { Category } from "model/category";
import Link from "next/link";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { motion } from "framer-motion";
// import Image from "next/image";

interface Props {
  category?: Category[];
}

const BannerMentor: FC<Props> = ({ category }) => {
  const { darkmode } = useDarkmode();
  const router = useRouter();
  const appState = useAppState();
  return (
    <Box py={{ sm: 32, base: 10 }}>
      <Container>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexWrap={{ base: "wrap", sm: "nowrap" }}
        >
          <Box width={{ sm: "50%", base: "100%" }}>
            <Stack maxW={700}>
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <Badge bg={"gray.800"} p={2} mb={2}>
                  <Heading
                    fontSize={{ sm: "2xl", base: "xl" }}
                    color={darkmode == true ? "gray.100" : "gray.100"}
                    fontWeight={"bold"}
                  >
                    #semuabisakoding
                  </Heading>
                </Badge>

                <Heading
                  fontSize={{ sm: "6xl", base: "4xl" }}
                  fontWeight={"bold"}
                  color={darkmode == true ? "gray.200" : "gray.900"}
                >
                  Bangun Karir Masa Depan Bersama <br />
                  <Text
                    as={"span"}
                    bgGradient="linear(to-r, gray.400,pink.400)"
                    bgClip="text"
                  >
                    Mistercoding
                  </Text>{" "}
                </Heading>
                <Text
                  fontSize={{ sm: "xl", base: "lg" }}
                  color={darkmode == true ? "gray.200" : "gray.700"}
                >
                  Siapkan dirimu mulai dari sekarang, mulai asah skill dan
                  belajar coding dari dasar sampai mahir
                </Text>
                <Box py={4}>
                  <Button
                    display={{ base: "none", md: "inline-flex" }}
                    rounded={"xl"}
                    px={{ base: 8 }}
                    mx={1}
                    fontSize={"lg"}
                    fontWeight={600}
                    size={"lg"}
                    color={darkmode == true ? "gray.600" : "white"}
                    bg={darkmode == true ? "gray.100" : "brand.500"}
                    _hover={{
                      opacity: 0.9,
                    }}
                    onClick={() => {
                      appState.setLoading(true);
                      router.replace("/kelas");
                    }}
                  >
                    Mulai Sekarang
                  </Button>
                </Box>
              </motion.div>
            </Stack>
          </Box>

          <Box width={{ sm: "50%", base: "100%" }}>
            <motion.div
              initial={{ opacity: 0, scale: 0.7 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.5 }}
            >
              {/* <Image
                width={"100%"}
                src={cdn2("mistercoding_mac_2958867abf.png")}
              /> */}
              <Image
                // width={750} height={450}
                src={"https://img.picopixs.com/mrcodingimg/mister_coding_banner_65ad15f1b5.png?w=620"}
                alt="Banner"
              />
            </motion.div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BannerMentor;
