import { darkmodeState } from "@/state/darkmode.state";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Image,
} from "@chakra-ui/react";
import useDarkmode from "@/hooks/userDarkmode";

interface Questions {
  ques: string;
  ans: string;
}
const FAQ = () => {
  const questions: Questions[] = [
    {
      ques: "Apakah Mistercoding.com?",
      ans: "Mistercoding adalah platform belajar online khususnya seputar IT yang menyediakan berupa artikel dan kelas online",
    },
    {
      ques: "Apakah Mistercoding.com gratis?",
      ans: " Ya, Kamu bisa mengakses mistercoding.com secara gratis, namun ada kelas yang versi berbayar dengan lebih banyak benefit yang didapatkan",
    },
    {
      ques: "Siapa saja yang bisa mengakses untuk belajar di Mistercoding?",
      ans: "Semua orang yang ingin belajar dan mengembangkan skill dibidang IT",
    },
    {
      ques: "Bagaimana proses belajar di Mistercoding.com?",
      ans: "Untuk belajar di Mistercoding.com, kamu cukup membaca artikel atau mengikuti kelas yang tersedia",
    },
    {
      ques: "Apakah tersedia sertifikat?",
      ans: "kamu akan mendapatkan sertifikat setelah mengikuti kelas, menyelesaikan kelas, dan mengikuti Ujian",
    },
    {
      ques: "Apakah yang dimaksud akses kelas selamanya?",
      ans: "Akses selamanya artinya bisa mengakses materi selamanya asalkan kelas masih tersedia di Mistercoding.",
    },
  ];

  const {darkmode} = useDarkmode();
  
  return (
    <Box>
      <Center>
        <Heading fontSize={23} color={darkmode==true?"gray.100":"gray.600"}>
          Frequently Asked Questions
        </Heading>
      </Center>
      <Box py={10}>
        <Accordion allowMultiple>
          {questions.map((item, i) => {
            return (
              <AccordionItem key={i} py={4}>
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={"bold"}
                      color={darkmode==true?"gray.100":"gray.600"}
                    >
                      {item.ques}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color={darkmode==true?"gray.200":"gray.600"}>{item.ans}</AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    </Box>
  );
};

export default FAQ;
