import Layout from "@/layouts/Layout";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react";
import Container from "@/components/Container";
import { useAppState } from "store/hookstate/app.state";
import { useEffect, useState } from "react";
import BannerImage from "@/components/banners/BannerImage";
import { NextSeo } from "next-seo";
import { GetServerSideProps } from "next";
import { api } from "@/config/api";
import axios from "axios";
import { Article as Art } from "model/blog";
import Article from "@/features/article/Article";
import { Tag } from "model/tag";
import Tags from "@/features/article/Tags";
import { AiFillTag } from "react-icons/ai";
import { Category } from "model/category";
import { Admin } from "model/admin";
import FAQ from "@/components/FAQ";
import JoinUseSection from "@/components/JoinUsSection";
import useDarkmode from "@/hooks/userDarkmode";
import BannerMentor from "@/components/banners/BannerMentor";
// import NewClassSlide from "@/components/class/NewClassSlide";
import queryString from "query-string";
// import ReviewSlide from "@/features/review/ReviewSlide";
import { StarReview } from "ui";
import Link from "next/link";
// import PopularClassSlide from "@/components/class/PopulerClassSlide";
// import NewBootcampSlide from "@/components/class/NewBootcampSlide";
import BannerBootcamp from "@/components/banners/BannerBootcamp";
// import EbookPromo from "@/components/promo/EbookPromo";
import dynamic from "next/dynamic";

const NewClassSlide = dynamic(() => import("@/components/class/NewClassSlide"), {
  ssr: false,
});

const EbookPromo = dynamic(() => import("@/components/promo/EbookPromo"), {
  ssr: false,
});


const NewBootcampSlide = dynamic(() => import("@/components/class/NewBootcampSlide"), {
  ssr: false,
});

const ReviewSlide = dynamic(() => import("@/features/review/ReviewSlide"), {
  ssr: false,
});

const PopularClassSlide = dynamic(() => import("@/components/class/PopulerClassSlide"), {
  ssr: false,
});


interface Props {
  data: Art[];
  data_admin: Admin[];
  datatags: Tag[];
  category: Category[];
}

export default function Home({ data, datatags, category, data_admin }: Props) {
  const appState = useAppState();
  const [dd, setDd] = useState<Art[]>();

  useEffect(() => {
    appState.setLoading(false);
    const dataNew = data?.map((item) => {
      const findAdmin = data_admin?.find(
        (admin) =>
          admin.attributes.username ==
          item.attributes.createdBy.data.attributes.username
      );
      if (findAdmin) {
        item["attributes"]["createdBy"]["data"]["attributes"] = {
          ...item["attributes"]["createdBy"]["data"]["attributes"],
          detail: findAdmin,
        };
      }
      return {
        ...item,
      };
    });
    setDd(dataNew);
  }, []);

  const { darkmode } = useDarkmode();
  return (
    <>
      <NextSeo
        title="Website Belajar Koding Indonesia - Mister Coding"
        description="Kembangkan karir sebagai Software Engineer Profesional dengan mempelajari Coding mulai dari sekarang"
        canonical="https://www.mistercoding.com"
        openGraph={{
          url: "https://www.mistercoding.com",
          title: "Website Belajar Koding Indonesia - Mister Coding",
          description:
            "Kembangkan karir sebagai Software Engineer Profesional dengan mempelajari Coding mulai dari sekarang",
          images: [
            {
              url: "https://res.cloudinary.com/dlppzoemc/image/upload/v1679199626/mistercoding-class/macbook_hp_mcs_mtnshx.png",
              width: 800,
              height: 600,
              alt: "Mister Coding Course",
              type: "image/jpeg",
            },
            {
              url: "https://res.cloudinary.com/dlppzoemc/image/upload/v1679819950/mistercoding-class/mca_course_b0w8ix.png",
              alt: "Mister Coding Course",
              type: "image/jpeg",
            },
          ],
          siteName: "www.mistercoding.com",
        }}
      />
      <Layout>
        {/* <EbookPromo /> */}
        <BannerMentor category={category} />
        {/* <Container>
          <MainBanner />
        </Container> */}

        <Box
          bgGradient={
            darkmode == true
              ? "linear(to-b, gray.900, gray.800, gray.700, gray.700, gray.700, gray.800, gray.900)"
              : "linear(to-r, gray.50, gray.50)"
          }
        >
          <BannerImage />
        </Box>
        {/* <Box py={{sm:16,base:10}} bgGradient={"linear(to-b, white, gray.100, white)"}>
          <Heading px={10} textAlign={"center"} color={"gray.600"} mt={10} fontSize={"3xl"}>Menjadi Expert Bersama Mister Coding</Heading>
          <Text mb={16} color={"gray.600"} fontSize={"lg"} textAlign={"center"}>Jadilah expert dengan metode belajar yang cocok dan di suport oleh mentor berpengalaman</Text>
          <Container>
            <Box maxW={"4xl"} mx={"auto"}>
              <MainFeature />
            </Box>
          </Container>
        </Box> */}

        <Box py={35} bg={"white"}>
          <Container>
            {/* <Box p={{sm:8,base:4}} bg={"gray.100"} rounded={"2xl"}>
              <NewClassSlide />
            </Box> */}

            <Box my={10}>
              <PopularClassSlide show={3.5} />
            </Box>

            <Box my={10}>
              <NewClassSlide show={3.5} />
            </Box>

            {/* <Box my={8}>
              <Text
                fontWeight={"bold"}
                fontSize={{ sm: "2xl", base: "xl" }}
                color={darkmode == true ? "gray.100" : "gray.600"}
              >
                Events
              </Text>
              <Text fontSize={"md"} color={darkmode == true ? "gray.300" : "gray.600"}>
                Ikuti events dari mistercoding
              </Text>
              <Box mt={8}>
                <Events />
              </Box>
            </Box> */}
          </Container>
        </Box>

        <Box bgGradient={"linear(to-r, gray.700, gray.600)"}>
          {/* <BannerBootcamp /> */}
        </Box>

        <Box>
          <Container>
            <Box my={10}>
              <NewBootcampSlide show={3.5} />
            </Box>
          </Container>
        </Box>

        <Box
          py={{ sm: 120, base: 30 }}
          bgGradient={"linear(to-r, gray.100, gray.50)"}
        >
          <Container>
            <Box>
              <Box>
                <Text
                  fontWeight={"bold"}
                  fontSize={{ sm: "2xl", base: "xl" }}
                  color={darkmode == true ? "gray.100" : "gray.600"}
                >
                  Artikel
                </Text>
                <Text
                  fontSize={"md"}
                  color={darkmode == true ? "gray.300" : "gray.600"}
                >
                  Cari artikel yang kamu inginkan, mulai belajar coding dari
                  membaca
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexWrap={{ sm: "nowrap", base: "wrap" }}
                mt={8}
              >
                <Box w={{ sm: "70%", base: "100%" }}>
                  {dd && (
                    <>
                      <Article data={dd} />
                      <Center>
                        <Link href={`/post`}>
                          <Button
                            my={10}
                            bg={"gray.800"}
                            color={"gray.100"}
                            _hover={{ bg: "gray.600" }}
                          >
                            More Article...
                          </Button>
                        </Link>
                      </Center>
                    </>
                  )}
                </Box>
                <Box
                  width={{ sm: "30%", base: "100%" }}
                  px={{ sm: 4, base: 0 }}
                  my={{ sm: 0, base: 10 }}
                >
                  <Card>
                    <CardBody>
                      <Flex>
                        <Icon as={AiFillTag} size={20} />
                        <Text mb={4} ml={2}>
                          Tags
                        </Text>
                      </Flex>

                      <Tags tag={datatags} />
                    </CardBody>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box bg={"gray.700"} py={10} display={{ sm: "block", base: "none" }}>
          <Heading textAlign={"center"} color={"gray.200"}>
            Ulasan Member
          </Heading>
          <Text textAlign={"center"} color={"gray.300"} fontSize={"xl"}>
            Bergabunglah dengan 1000+ member lainya
          </Text>
          <Center>
            <StarReview value={5} isDisabled={true} onChange={() => {}} />
          </Center>
          <Box my={16} mx={"auto"} maxW={"85vw"}>
            <ReviewSlide />
          </Box>
        </Box>

        <Box>
          <Box my={70} maxW={"4xl"} mx={"auto"}>
            <Container>
              <FAQ />
            </Container>
          </Box>
        </Box>

        <Box py={10}>
          <Box maxW={"4xl"} my={20} mx={"auto"} px={6}>
            <JoinUseSection />
          </Box>
        </Box>

        {/* {
          darkmode == false && <>
            <Box bg={"gray.50"} py={70}>
              <Container>
                <Box maxW={"xl"} mx={"auto"}>
                  <PaymentMethods />
                </Box>
              </Container>
            </Box>
          </>
        } */}

        {/* <Box>
          <TickerBrand />
        </Box> */}
      </Layout>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ params }) => {
  try {
    const paramQuery: any = {
      populate: "*",
      "sort[id]": "desc",
      "pagination[start]": 0,
      "pagination[limit]": 3,
    };

    paramQuery["filters[post_type][slug]"] = "post";

    // const { data } = await axios.get(api.api_blog_url + "/posts", {
    //   params: paramQuery,
    // });

    const { data } = await axios.get(api.api_url + "/cache/v1/proxy", {
      params: {
        dest: api.api_blog_url + "/posts?" + queryString.stringify(paramQuery),
        key: "posts:all",
        ttl: 10000,
      },
    });

    const { data: data_admin } = await axios.get(
      api.api_url + "/cache/v1/proxy",
      {
        params: {
          dest: api.api_blog_url + "/admin-details?populate=*",
          key: "post:admin_details",
        },
      }
    );

    // const { data: data_admin } = await axios.get(
    //   api.api_blog_url + "/admin-details?populate=*"
    // );

    const { data: datatags } = await axios.get(
      api.api_url + "/cache/v1/proxy",
      {
        params: {
          dest: api.api_blog_url + "/post-tags",
          key: "post:tags",
        },
      }
    );

    // const { data: datatags } = await axios.get(api.api_blog_url + "/post-tags");

    const { data: dataCategories } = await axios.get(
      api.api_url + "/cache/v1/proxy",
      {
        params: {
          dest: api.api_blog_url + "/post-categories?populate=thumbnail",
          key: "post:categories",
        },
      }
    );

    // const { data: dataCategories } = await axios.get(
    //   api.api_blog_url + "/post-categories?populate=thumbnail"
    // );

    if (!data.data) {
      return {
        notFound: true,
      };
    }
    return {
      props: {
        data: data.data ?? {},
        data_admin: data_admin.data ?? {},
        datatags: datatags.data ?? [],
        category: dataCategories.data ?? [],
      },
    };
  } catch (error) {}
  return {
    props: {},
  };
};
