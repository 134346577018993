import { darkmodeState } from "@/state/darkmode.state";
import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import Link from "next/link";
import useDarkmode from "@/hooks/userDarkmode";

const JoinUseSection = () => {
    const {darkmode} = useDarkmode();
    return (
        <Box minH={100} bg={darkmode==true?"gray.800":"gray.700"} rounded={"xl"} p={4} py={8} textAlign={"center"}>
            <Flex justifyContent={"center"}>
                <Box maxW={"2xl"}>
                    <Text fontWeight={"bold"} fontSize={"2xl"} color={"gray.100"}>
                        Bangun karir dan raih impianmu sekarang
                    </Text>
                    <Text fontSize={"lg"} color={"gray.100"} mt={4}>
                        Menjadi Frontend Developer, Backend Developer, Full Stack Developer atau buat Startup sendiri. Belajar secara terstruktur dengan materi yang ajarkan oleh mentor berpengalaman
                    </Text>
                    <Box mt={6}>
                        <Link href={"/auth/sign-up"}>
                            <Button>Daftar Sekarang</Button>
                        </Link>
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}

export default JoinUseSection;