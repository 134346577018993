import { Article, Attributes } from "model/blog";
import { Course } from "model/course";
import { useAppState } from "store/hookstate/app.state";
import {
  Card,
  CardBody,
  Heading,
  Image,
  Stack,
  Text,
  Box,
  Avatar,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React from "react";
import ReactMarkdown from "ui/components/ReactMarkdown";
import ReactTimeAgo from "react-time-ago";
import { cdn2 } from "@/utils/image";
import useDarkmode from "@/hooks/userDarkmode";

const CardArticle: React.FC<Article> = function ({ attributes }) {
  const router = useRouter();
  const appState = useAppState();
  const { darkmode } = useDarkmode();
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      // variant="outline"
      as={Link}
      href={`/post/${attributes.slug}`}
      _hover={{ opacity: 0.8 }}
    >
      <Box>
        <Image
          objectFit="cover"
          w={{ base: "100%", sm: "270px" }}
          maxW={{ sm: "270px" }}
          src={`https://img.picopixs.com/mistercoding-blog/${attributes?.thumbnail?.data.attributes.hash}${attributes?.thumbnail?.data.attributes.formats.thumbnail.ext}?w=240`}
          // h={185}
          // src={cdn2(attributes?.thumbnail?.data.attributes.hash + attributes?.thumbnail?.data.attributes.formats.thumbnail.ext)}
        />
      </Box>

      <Stack>
        <CardBody>
          <Heading size="md">{attributes.title}</Heading>
          <Box noOfLines={2} pt={2}>
            <ReactMarkdown children={attributes.short_description} />
          </Box>

          <Flex justifyContent={"space-between"} alignItems="end">
            <Flex mt={4} alignItems={"center"}>
              <Avatar
                size="sm"
                name="Dan Abrahmov"
                src={
                  cdn2('mister_3ad460a1b7.png')
                }
              />
              <Box>
                <Text
                  fontWeight={"bold"}
                  fontSize={"xs"}
                  ml={3}
                  color={"gray.600"}
                >
                  Mister Coding
                </Text>
              </Box>
            </Flex>

            <Box fontSize={"sm"} color={"gray.600"}>
              <ReactTimeAgo
                date={new Date(attributes.createdAt)}
                locale="en-US"
              />
            </Box>
          </Flex>
        </CardBody>
      </Stack>
    </Card>
  );
};

export default CardArticle;
