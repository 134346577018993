import { useLazyGetBlogsQuery } from "@/store/queries/blog.api";
import { Box, Text, Grid, Button } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import CardArticle from "./CardArticle";
import { Article as Art } from "model/blog";
import { chunk } from "lodash";
import useDarkmode from "@/hooks/userDarkmode";

interface Props {
  data: Art[];
  more?: boolean;
}

const Article: FC<Props> = ({ data, more }) => {
  const [dataPost, setDataPost] = useState<any>(chunk(data, 4));
  const [dataPostArticle, setDataPostArticle] = useState<any>(dataPost[0]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setDataPost(chunk(data, 4));
  }, [data]);

  useEffect(() => {
    setDataPostArticle(dataPost[0]);
  }, [dataPost]);

  const moreHandle = () => {
    if (page < dataPost.length) {
      const pg = page + 1;
      if (dataPost[pg]) {
        setPage(pg);
        setDataPostArticle([...dataPostArticle, ...dataPost[pg]]);
      }
    }
  };

  
  const {darkmode} = useDarkmode();
  
  return (
    <Box>
      <Grid
        templateColumns={{ base: "repeat(1,1fr)", sm: "repeat(1,1fr)" }}
        gap={4}
      >
        {dataPostArticle?.map((item: Art, i: number) => {
          return (
            <Box key={i}>
              <CardArticle {...item} />
            </Box>
          );
        })}
      </Grid>
      {more == true && (
        <Box my={4} textAlign={"center"}>
          <Button
            bg={darkmode==true?"gray.200":"brand.500"}
            color={darkmode==true?"gray.800":"white"}
            px={10}
            _hover={{ bg: "brand.500" }}
            onClick={moreHandle}
          >
            More..
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Article;
