import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import Container from "../Container";
import useDarkmode from "@/hooks/userDarkmode";
import { IoMdCheckmarkCircle } from "react-icons/io";

const BannerImage = () => {
  const { darkmode } = useDarkmode();
  return (
    <Box py={{ sm: 32, base: 10 }}>
      <Container>
        <Box display={"flex"} flexWrap={{ base: "wrap", sm: "nowrap" }}>
          <Box width={{ sm: "55%", base: "100%" }}>
            <Box rounded={"3xl"} minH={400} bg={"gray.700"} p={8}>
              <Heading fontSize={"3xl"} color={"gray.100"}>
                Why Mistercoding?
              </Heading>
              <Text  mb={6} color={"gray.200"}>Belajar mudah kapan saja dan darimana saja</Text>
              <HStack alignItems={"center"} my={4}>
                <Icon
                  color={"white"}
                  fontSize={"2xl"}
                  as={IoMdCheckmarkCircle}
                />
                <Text color={"gray.300"} ml={1}>
                  Materi terstruktur
                </Text>
              </HStack>
              <HStack alignItems={"center"} my={4}>
                <Icon
                  color={"white"}
                  fontSize={"2xl"}
                  as={IoMdCheckmarkCircle}
                />
                <Text color={"gray.300"} ml={1}>
                  Akses kelas selamanya
                </Text>
              </HStack>
              <HStack alignItems={"center"} my={4}>
                <Icon
                  color={"white"}
                  fontSize={"2xl"}
                  as={IoMdCheckmarkCircle}
                />
                <Text color={"gray.300"} ml={1}>
                  Mentor berpengalaman
                </Text>
              </HStack>
              <HStack alignItems={"center"} my={4}>
                <Icon
                  color={"white"}
                  fontSize={"2xl"}
                  as={IoMdCheckmarkCircle}
                />
                <Text color={"gray.300"} ml={1}>
                  Sertifikat Kelulusan
                </Text>
              </HStack>
              <HStack alignItems={"center"} my={4}>
                <Icon
                  color={"white"}
                  fontSize={"2xl"}
                  as={IoMdCheckmarkCircle}
                />
                <Text color={"gray.300"} ml={1}>
                  Kualitas video Full HD
                </Text>
              </HStack>
              <HStack alignItems={"center"} my={4}>
                <Icon
                  color={"white"}
                  fontSize={"2xl"}
                  as={IoMdCheckmarkCircle}
                />
                <Text color={"gray.300"} ml={1}>
                  Mudah diakses
                </Text>
              </HStack>
            </Box>
            {/* <Image
              width={"100%"}
              src="https://res.cloudinary.com/dlppzoemc/image/upload/v1689095936/mistercoding-class/banner_mistercoding_mac_ntdi2i.png"
            /> */}
          </Box>
          <Box
            px={{ sm: 8, base: 4 }}
            width={{ sm: "45%", base: "100%" }}
            py={20}
          >
            {/* <Text color={darkmode == true ? "gray.100" : "gray.700"} fontWeight={"bold"} fontSize={"2xl"}>
              LMS System
            </Text> */}
            <Heading
              color={darkmode == true ? "gray.100" : "gray.700"}
              fontWeight={"bold"}
              fontSize={"4xl"}
            >
              Belajar secara Mudah dan Fleksibel
            </Heading>
            <Text
              color={darkmode == true ? "gray.100" : "gray.600"}
              fontSize={"lg"}
              mt={2}
            >
              Belajar bisa kapan saja dan darimana saja secara fleksibel, Hanya
              dengan menggunakan Gadget dan Internet melalui video, diskusi dan
              bertanya langsung kepada mentor.
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BannerImage;
